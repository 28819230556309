import React from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'
import { CheckboxField } from '@elo-kit/components/form/checkbox-field/CheckboxField'

import { getCancellationTermsShopLink } from 'utils/helpersShared.utils'
import { cleanupHTMLStyleFonts } from 'utils/fonts.utils'

interface Props {
  checked: boolean
  htmlTerms: {
    id?: number
    value?: string
    checkboxVisible?: boolean
  }
  link: string
  onCheckboxChange: (value: boolean) => void
  productType: string
  areHiddenCancellationTerms: boolean
  invoiceToken: string
  sellerUsername: string
  paymentPageColor: string
  customCss?: string
  isPowerSeller?: boolean
  usePowerSellerDocument?: boolean
  isFree: boolean
  disabled?: boolean
}

export const PurchaseTerms: React.FC<Props> = observer(function PurchaseTerms(props) {
  const {
    checked,
    htmlTerms,
    link,
    onCheckboxChange,
    productType,
    areHiddenCancellationTerms,
    sellerUsername,
    usePowerSellerDocument,
    paymentPageColor,
    isPowerSeller,
    isFree = false,
    disabled = false,
  } = props

  const { checkboxVisible, value, id } = htmlTerms || {}

  const shopCancellationLink = getCancellationTermsShopLink(sellerUsername, id, productType)

  const enabledCheckboxStyle =
    checked && paymentPageColor
      ? {
          backgroundColor: paymentPageColor,
          borderColor: paymentPageColor,
        }
      : {}

  const cancelTermsText = (value || '').replace(/%{link}/gi, shopCancellationLink)
  const namottoHref = `/s/${sellerUsername}/document/terms_of_business`
  const termsHref = `/s/${sellerUsername}/document/terms_of_business${isPowerSeller ? '?power_seller=true' : ''}`
  const sellerTermsKey =
    isPowerSeller && usePowerSellerDocument
      ? I18n.t('react.shared.accept_terms.payment_namotto', {
          terms_href: `${termsHref}`,
          namotto_href: `${namottoHref}`,
        })
      : I18n.t('react.shared.accept_terms.payment', {
          terms_href: `${termsHref}`,
          namotto_href: `${namottoHref}`,
        })

  const checkTexBoxClasses = classNames('cancel-terms__text cancel-terms__text--radio', {
    'cancel-terms--required': !isFree,
  })

  return (
    <div className='terms'>
      <div
        className='seller-terms'
        dangerouslySetInnerHTML={{
          __html: sellerTermsKey,
        }}
      />
      {!areHiddenCancellationTerms &&
        (checkboxVisible ? (
          <div className='cancel-terms'>
            <CheckboxField
              checked={checked}
              disabled={disabled}
              onChange={() => onCheckboxChange(!checked)}
              style={enabledCheckboxStyle}
            />
            <div
              className={checkTexBoxClasses}
              dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(cancelTermsText) }}
            />
          </div>
        ) : (
          value && (
            <div
              className='cancel-terms__text'
              dangerouslySetInnerHTML={{ __html: cleanupHTMLStyleFonts(cancelTermsText) }}
            />
          )
        ))}
      {link && (
        <div className='back-link'>
          <a href={link}>{I18n.t('react.shop.payment.back_to_api_site', { site: link })}</a>
        </div>
      )}
    </div>
  )
})
